import React from 'react';
import { graphql } from 'gatsby';
import { Link, useIntl } from 'gatsby-plugin-intl';
import { v4 as uuidv4 } from 'uuid';

import Layout from '../layout';
import SEO from '../components/SEO';
import './blog.css';
import Footer from '../components/Footer/index';
import Header from '../components/Blog/pageHeader';
import BlogList from '../components/Blog/blogList';

const BlogPage = (props) => {
  const intl = useIntl();
  const renderPaging = () => {
    const { currentPageNum, pageCount = 1 } = props.pageContext;
    const prevPage =
      currentPageNum - 1 === 1 ? '/blog/' : `/blog/${currentPageNum - 1}/`;
    const nextPage = `/blog/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    if (pageCount === 1) {
      return null;
    }

    return (
      <nav className="paging-container site-content-aside">
        <ol className="articles pagination pagination-inline">
          {!isFirstPage && (
            <li>
              <Link to={prevPage}>Previous</Link>
            </li>
          )}
          {[...Array(pageCount)].map((_val, index) => {
            const pageNum = index + 1;
            return (
              <li
                className={currentPageNum === pageNum ? 'active' : ''}
                key={uuidv4()}
              >
                {currentPageNum !== pageNum ? (
                  <Link
                    to={
                      pageNum === 1
                        ? '/press-release/'
                        : `/press-release/${pageNum}/`
                    }
                  >
                    {' '}
                    {pageNum}{' '}
                  </Link>
                ) : (
                  <span>{pageNum}</span>
                )}
              </li>
            );
          })}
          {!isLastPage && (
            <li>
              <Link to={nextPage}>Next</Link>
            </li>
          )}
        </ol>
      </nav>
    );
  };

  const postEdges = props.data.allMarkdownRemark.edges;

  return (
    <Layout>
      <Header />
      <SEO
        meta={{
          title: `${intl.formatMessage({
            id: 'blog.title',
          })} | ${intl.formatMessage({ id: 'title' })}`,
          desc: intl.formatMessage({ id: 'blog.subHeading' }),
          slug: `/blog`,
        }}
      />

      <BlogList postEdges={postEdges} />
      <Footer>{renderPaging()}</Footer>
    </Layout>
  );
};

export default BlogPage;

/* eslint no-undef: "off" */
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog-post/.*\\\\.md$/" } }
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
