import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

function heading({ noh = false }) {
  const intl = useIntl();
  return (
    <>
      <div className="masthead masthead-blog">
        <div className="masthead-contents">
          <div className="masthead-text">
            {noh ? (
              <div className="h1 small">
                <strong>{intl.formatMessage({ id: 'blog.title' })}</strong>
              </div>
            ) : (
              <h1 className="small">
                <strong>{intl.formatMessage({ id: 'blog.title' })}</strong>
              </h1>
            )}

            <p className="sub-lead">
              {intl.formatMessage({ id: 'blog.subHeading' })}
            </p>
          </div>
        </div>
      </div>
      <div className="masthead-placeholder" />
    </>
  );
}

export default heading;
