import React from 'react';
import PostListing from '../PostListing/postListing';

function blogContent(props) {
  const { postEdges } = props;
  return (
    <>
      <div className="site-wrap">
        <div className="site-container">
          <div className="site-content site-content-aside site-content-posts">
            <div className="articles">
              <PostListing postEdges={postEdges} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default blogContent;
