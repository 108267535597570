import React from 'react';
import { Link } from 'gatsby-plugin-intl';

const postListing = (props) => {
  const getPostList = () => {
    const postList = [];
    props.postEdges.forEach((postEdge) => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
      });
    });
    return postList;
  };

  const postList = getPostList();
  return postList.map((post) => (
    <article key={post.title}>
      <time dateTime={post.date}>{post.date}</time>
      <h2>
        <Link to={`/blog/${post.path}`}>{post.title}</Link>
      </h2>
      <p>{post.excerpt}</p>
      <hr />
    </article>
  ));
};

export default postListing;
